<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('id') }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ subscription.uuid }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('state') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.active">
          {{ $t('active') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="!subscription.active">
          {{ $t('inactive') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item @click="selectPayer">
      <v-list-item-content>
        <v-list-item-title>{{ $t('payer') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.payer && subscription.payer.address && subscription.payer.address.name_line">
          {{ subscription.payer.address.name_line }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item @click="selectReceiver">
      <v-list-item-content>
        <v-list-item-title>{{ $t('receiver') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.receiver && subscription.receiver.address && subscription.receiver.address.name_line">
          {{ subscription.receiver.address.name_line }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          {{ $t('null') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('item') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.item">
          {{ subscription.item.title }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          {{ $t('null') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('recurringPrice') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.recurringPrice && subscription.recurringPrice.amount && subscription.recurringPrice.currency_code">
          {{ subscription.recurringPrice.amount }} {{ subscription.recurringPrice.currency_code }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          {{ $t('null') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('itemPrice') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.item && subscription.item.price && subscription.item.price.amount && subscription.item.price.currency_code">
          {{ subscription.item.price.amount }} {{ subscription.item.price.currency_code }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          {{ $t('null') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('cycles') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.cycle && (subscription.cycles || subscription.cycles === 0)">
          {{ subscription.cycle }} / {{ subscription.cycles }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          {{ $t('null') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('created') }}</v-list-item-title>
        <v-list-item-subtitle>{{ subscription.created | moment('DD/MM/YYYY') }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('changed') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.changed">
          {{ subscription.changed | moment('DD/MM/YYYY') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          {{ $t('null') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('nextPayment') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.nextPayment">
          {{ subscription.nextPayment | moment('DD/MM/YYYY') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          {{ $t('null') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('expires') }}</v-list-item-title>
        <v-list-item-subtitle v-if="subscription.expires">
          {{ subscription.expires | moment('DD/MM/YYYY') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          {{ $t('noExpiration') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
    name: 'SubscriptionDetails',
    props: {
        subscription: {
            type: Object,
            default: () => {}
        }
    },
    data:() => ({
      now: new Date()
    }),
    methods: {
      selectPayer() {
        this.$store.commit('updateContextOverlay', {
          'component':  'components/customer/CustomerProfile',
          'props': {
            'uuid': this.subscription.payer.uuid
          }
        })
      },
      selectReceiver() {
        this.$store.commit('updateContextOverlay', {
          'component':  'components/customer/CustomerProfile',
          'props': {
            'uuid': this.subscription.receiver.uuid
          }
        })
      }
      //TODO: select Item with updated context overlay for subscription item
    }
}
</script>
